import { useState, useEffect } from 'react';
import { supabase } from '../lib/supabase';
import { Reminder } from '../types/reminder';

export function useReminders() {
  const [reminders, setReminders] = useState<Reminder[]>([]);
  const [loading, setLoading] = useState(true);

  const loadReminders = async () => {
    const { data: { session } } = await supabase.auth.getSession();
    if (!session) return;

    const { data, error } = await supabase
      .from('reminders')
      .select('*')
      .eq('user_id', session.user.id)
      .order('datetime', { ascending: true });

    if (error) {
      console.error('Error loading reminders:', error);
    } else {
      setReminders(data || []);
    }
    setLoading(false);
  };

  const createReminder = async (reminder: Omit<Reminder, 'id' | 'user_id' | 'created_at'>) => {
    const { data: { session } } = await supabase.auth.getSession();
    if (!session) return;

    const { error } = await supabase
      .from('reminders')
      .insert([
        {
          user_id: session.user.id,
          ...reminder,
        },
      ]);

    if (error) {
      console.error('Error creating reminder:', error);
    } else {
      loadReminders();
    }
  };

  const updateReminder = async (id: string, reminder: Partial<Omit<Reminder, 'id' | 'user_id' | 'created_at'>>) => {
    const { error } = await supabase
      .from('reminders')
      .update(reminder)
      .eq('id', id);

    if (error) {
      console.error('Error updating reminder:', error);
    } else {
      loadReminders();
    }
  };

  const deleteReminder = async (id: string) => {
    const { error } = await supabase
      .from('reminders')
      .delete()
      .eq('id', id);

    if (error) {
      console.error('Error deleting reminder:', error);
    } else {
      loadReminders();
    }
  };

  useEffect(() => {
    loadReminders();
  }, []);

  return {
    reminders,
    loading,
    createReminder,
    updateReminder,
    deleteReminder,
  };
}