import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { useSubscription } from '../hooks/useSubscription';
import { useReminders } from '../hooks/useReminders';
import { ReminderForm } from '../components/ReminderForm';
import { ReminderList } from '../components/ReminderList';
import { Loader2 } from 'lucide-react';

export function Dashboard() {
  const navigate = useNavigate();
  const { user, loading: authLoading } = useAuth();
  const { status: subscriptionStatus, loading: subscriptionLoading } = useSubscription();
  const { reminders, loading: remindersLoading, createReminder, updateReminder, deleteReminder } = useReminders();

  useEffect(() => {
    if (!authLoading && !user) {
      navigate('/login');
      return;
    }

    if (!subscriptionLoading && !subscriptionStatus.isSubscribed) {
      // Redirect to Stripe payment page if no active subscription
      window.location.href = 'https://buy.stripe.com/dR614rfRr7trdQA000';
      return;
    }
  }, [user, authLoading, subscriptionStatus, subscriptionLoading, navigate]);

  if (authLoading || subscriptionLoading || remindersLoading) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="text-center">
          <Loader2 className="h-8 w-8 animate-spin text-indigo-600 mx-auto" />
          <p className="mt-2 text-gray-600">Loading...</p>
        </div>
      </div>
    );
  }

  if (!user || !subscriptionStatus.isSubscribed) {
    return null;
  }

  const userName = user.user_metadata?.full_name || 'User';

  return (
    <div className="min-h-screen bg-gray-50 p-4">
      <div className="max-w-4xl mx-auto space-y-6">
        <div className="bg-white p-6 rounded-lg shadow-sm">
          <h1 className="text-2xl font-bold text-gray-900 mb-2">Welcome, {userName}!</h1>
          <p className="text-gray-600 mb-6">Manage your reminders below</p>
          <div className="border-t pt-6">
            <h2 className="text-xl font-semibold text-gray-900 mb-4">Create New Reminder</h2>
            <ReminderForm onSubmit={createReminder} />
          </div>
        </div>

        <div>
          <h2 className="text-xl font-semibold text-gray-900 mb-4">Your Reminders</h2>
          <ReminderList
            reminders={reminders}
            onDelete={deleteReminder}
            onUpdate={updateReminder}
          />
        </div>
      </div>
    </div>
  );
}