import React from 'react';
import { Footer } from '../components/Footer';

export function Terms() {
  return (
    <div className="min-h-screen bg-gray-50">
      <div className="container mx-auto px-4 py-12">
        <div className="max-w-3xl mx-auto bg-white rounded-lg shadow-sm p-8">
          <h1 className="text-3xl font-bold mb-6">Terms and Conditions</h1>
          
          <div className="prose prose-indigo">
            <h2 className="text-xl font-semibold mt-8 mb-4">1. SMS Service Agreement</h2>
            <p className="mb-4">
              By using our reminder service, you agree to receive SMS messages from us. Message and data rates may apply. Message frequency varies based on your reminder settings. Text STOP to cancel at any time.
            </p>

            <h2 className="text-xl font-semibold mt-8 mb-4">2. Consent</h2>
            <p className="mb-4">
              By providing your phone number and using our service, you expressly consent to receive SMS messages from Reminder for your scheduled reminders. You acknowledge that consent is not a condition of purchase.
            </p>

            <h2 className="text-xl font-semibold mt-8 mb-4">3. Opt-Out Instructions</h2>
            <p className="mb-4">
              You can opt-out of SMS messages at any time by:
            </p>
            <ul className="list-disc ml-6 mt-2 mb-4">
              <li>Texting STOP to our SMS number</li>
              <li>Updating your preferences in your account settings</li>
              <li>Contacting our support team</li>
            </ul>

            <h2 className="text-xl font-semibold mt-8 mb-4">4. Message Frequency</h2>
            <p className="mb-4">
              Message frequency depends on your reminder settings. You control the frequency by managing your reminders in your dashboard.
            </p>

            <h2 className="text-xl font-semibold mt-8 mb-4">5. Support</h2>
            <p className="mb-4">
              For support with SMS messages, text HELP to our number or contact support@reminder.com.
            </p>

            <h2 className="text-xl font-semibold mt-8 mb-4">6. Carriers Supported</h2>
            <p className="mb-4">
              SMS messages are supported by all major U.S. carriers including AT&T, Verizon, T-Mobile, Sprint, and others.
            </p>

            <h2 className="text-xl font-semibold mt-8 mb-4">7. Changes to Terms</h2>
            <p className="mb-4">
              We reserve the right to modify these terms at any time. Continued use of the service after changes constitutes acceptance of new terms.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}