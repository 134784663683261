import React from 'react';
import { DateTimeInputs } from './DateTimeInputs';
import { FrequencySelector } from './FrequencySelector';

interface ReminderFormFieldsProps {
  message: string;
  date: string;
  time: string;
  frequency: 'once' | 'daily' | 'weekly' | 'monthly';
  onMessageChange: (value: string) => void;
  onDateChange: (value: string) => void;
  onTimeChange: (value: string) => void;
  onFrequencyChange: (value: 'once' | 'daily' | 'weekly' | 'monthly') => void;
}

export function ReminderFormFields({
  message,
  date,
  time,
  frequency,
  onMessageChange,
  onDateChange,
  onTimeChange,
  onFrequencyChange,
}: ReminderFormFieldsProps) {
  return (
    <>
      <div>
        <label htmlFor="message" className="block text-sm font-medium text-gray-700 mb-1">
          Reminder Message
        </label>
        <textarea
          id="message"
          value={message}
          onChange={(e) => onMessageChange(e.target.value)}
          className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-indigo-500 focus:border-indigo-500"
          rows={3}
          required
        />
      </div>

      <DateTimeInputs
        date={date}
        time={time}
        onDateChange={onDateChange}
        onTimeChange={onTimeChange}
      />

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Frequency
        </label>
        <FrequencySelector value={frequency} onChange={onFrequencyChange} />
      </div>
    </>
  );
}