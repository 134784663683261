import React from 'react';

interface FrequencySelectorProps {
  value: 'once' | 'daily' | 'weekly' | 'monthly';
  onChange: (frequency: 'once' | 'daily' | 'weekly' | 'monthly') => void;
}

export function FrequencySelector({ value, onChange }: FrequencySelectorProps) {
  const options = [
    { value: 'once', label: 'Once' },
    { value: 'daily', label: 'Daily' },
    { value: 'weekly', label: 'Weekly' },
    { value: 'monthly', label: 'Monthly' },
  ] as const;

  return (
    <div className="grid grid-cols-2 md:grid-cols-4 gap-3">
      {options.map((option) => (
        <button
          key={option.value}
          type="button"
          onClick={() => onChange(option.value)}
          className={`px-4 py-2 rounded-lg border ${
            value === option.value
              ? 'bg-indigo-600 text-white border-indigo-600'
              : 'border-gray-300 text-gray-700 hover:border-indigo-500'
          }`}
        >
          {option.label}
        </button>
      ))}
    </div>
  );
}