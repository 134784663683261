import React from 'react';
import { useNavigate } from 'react-router-dom';
import { CheckCircle2 } from 'lucide-react';
import { Footer } from '../components/Footer';

export function Checkout() {
  const navigate = useNavigate();

  // This is a placeholder - you'll need to implement the actual Stripe integration
  const handleSubscribe = () => {
    // Stripe checkout will be implemented here
    console.log('Stripe checkout will be implemented');
  };

  return (
    <>
      <div className="min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-xl mx-auto">
          <div className="text-center mb-12">
            <h1 className="text-3xl font-bold text-gray-900 mb-4">
              Complete Your Subscription
            </h1>
            <p className="text-lg text-gray-600">
              Start managing your reminders today with our premium plan
            </p>
          </div>

          <div className="bg-white rounded-2xl shadow-lg overflow-hidden">
            <div className="bg-indigo-600 p-6 text-center text-white">
              <h2 className="text-2xl font-bold mb-2">Premium Plan</h2>
              <div className="text-4xl font-bold mb-2">
                $9.99<span className="text-lg">/month</span>
              </div>
            </div>

            <div className="p-6 space-y-6">
              <ul className="space-y-4">
                {[
                  'Unlimited reminders',
                  'Custom recurring schedules',
                  'SMS notifications',
                  'Priority support',
                  'Advanced reminder settings',
                ].map((feature) => (
                  <li key={feature} className="flex items-start">
                    <CheckCircle2 className="h-6 w-6 text-green-500 flex-shrink-0" />
                    <span className="ml-3 text-gray-600">{feature}</span>
                  </li>
                ))}
              </ul>

              <button
                onClick={handleSubscribe}
                className="w-full bg-indigo-600 text-white py-3 px-4 rounded-lg hover:bg-indigo-700 transition-colors"
              >
                Subscribe Now
              </button>

              <p className="text-sm text-gray-500 text-center">
                Secure payment powered by Stripe
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}