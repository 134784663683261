import React from 'react';
import { ReminderCard } from './reminder/ReminderCard';
import { EmptyState } from './reminder/EmptyState';
import { Reminder } from '../types/reminder';

interface ReminderListProps {
  reminders: Reminder[];
  onDelete: (id: string) => void;
  onUpdate: (id: string, reminder: Partial<Omit<Reminder, 'id' | 'user_id' | 'created_at'>>) => void;
}

export function ReminderList({ reminders, onDelete, onUpdate }: ReminderListProps) {
  if (reminders.length === 0) {
    return <EmptyState />;
  }

  return (
    <div className="space-y-4">
      {reminders.map((reminder) => (
        <ReminderCard
          key={reminder.id}
          reminder={reminder}
          onDelete={onDelete}
          onUpdate={onUpdate}
        />
      ))}
    </div>
  );
}