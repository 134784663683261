import React, { useState } from 'react';
import { ReminderFormFields } from './reminder/ReminderFormFields';

interface ReminderFormProps {
  initialValues?: {
    message: string;
    date: string;
    time: string;
    frequency: 'once' | 'daily' | 'weekly' | 'monthly';
  };
  onSubmit: (reminder: {
    message: string;
    datetime: string;
    frequency: 'once' | 'daily' | 'weekly' | 'monthly';
  }) => void;
  submitLabel?: string;
}

export function ReminderForm({ initialValues, onSubmit, submitLabel = 'Create Reminder' }: ReminderFormProps) {
  const [message, setMessage] = useState(initialValues?.message ?? '');
  const [date, setDate] = useState(initialValues?.date ?? '');
  const [time, setTime] = useState(initialValues?.time ?? '');
  const [frequency, setFrequency] = useState<'once' | 'daily' | 'weekly' | 'monthly'>(
    initialValues?.frequency ?? 'once'
  );

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit({
      message,
      datetime: `${date}T${time}:00`,
      frequency,
    });

    if (!initialValues) {
      setMessage('');
      setDate('');
      setTime('');
      setFrequency('once');
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <ReminderFormFields
        message={message}
        date={date}
        time={time}
        frequency={frequency}
        onMessageChange={setMessage}
        onDateChange={setDate}
        onTimeChange={setTime}
        onFrequencyChange={setFrequency}
      />

      <button
        type="submit"
        className="w-full bg-indigo-600 text-white py-2 px-4 rounded-lg hover:bg-indigo-700 transition-colors"
      >
        {submitLabel}
      </button>
    </form>
  );
}