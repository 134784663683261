import React from 'react';
import { Footer } from '../components/Footer';

export function Privacy() {
  return (
    <div className="min-h-screen bg-gray-50">
      <div className="container mx-auto px-4 py-12">
        <div className="max-w-3xl mx-auto bg-white rounded-lg shadow-sm p-8">
          <h1 className="text-3xl font-bold mb-6">Privacy Policy</h1>
          
          <div className="prose prose-indigo">
            <h2 className="text-xl font-semibold mt-8 mb-4">1. Information Collection</h2>
            <p className="mb-4">
              We collect information you provide directly to us, including:
            </p>
            <ul className="list-disc ml-6 mt-2 mb-4">
              <li>Name and email address</li>
              <li>Phone number for SMS reminders</li>
              <li>Reminder preferences and settings</li>
              <li>Payment information (processed securely by Stripe)</li>
            </ul>

            <h2 className="text-xl font-semibold mt-8 mb-4">2. SMS Communications</h2>
            <p className="mb-4">
              When you opt in to receive SMS reminders:
            </p>
            <ul className="list-disc ml-6 mt-2 mb-4">
              <li>Your consent is recorded with timestamp and source</li>
              <li>You can opt out at any time by texting STOP</li>
              <li>Message history is retained for service improvement and legal compliance</li>
              <li>Your phone number is used solely for delivering your requested reminders</li>
            </ul>

            <h2 className="text-xl font-semibold mt-8 mb-4">3. Use of Information</h2>
            <p className="mb-4">
              We use your information to:
            </p>
            <ul className="list-disc ml-6 mt-2 mb-4">
              <li>Send your requested SMS reminders</li>
              <li>Process your subscription</li>
              <li>Improve our services</li>
              <li>Comply with legal obligations</li>
            </ul>

            <h2 className="text-xl font-semibold mt-8 mb-4">4. Data Security</h2>
            <p className="mb-4">
              We implement appropriate security measures to protect your personal information, including encryption of sensitive data and secure processing of payment information through Stripe.
            </p>

            <h2 className="text-xl font-semibold mt-8 mb-4">5. Data Retention</h2>
            <p className="mb-4">
              We retain your information for as long as necessary to provide our services and comply with legal obligations. SMS consent records are maintained for at least 4 years as required by telecommunications regulations.
            </p>

            <h2 className="text-xl font-semibold mt-8 mb-4">6. Your Rights</h2>
            <p className="mb-4">
              You have the right to:
            </p>
            <ul className="list-disc ml-6 mt-2 mb-4">
              <li>Access your personal information</li>
              <li>Correct inaccurate information</li>
              <li>Request deletion of your information</li>
              <li>Opt out of SMS communications</li>
              <li>Export your data</li>
            </ul>

            <h2 className="text-xl font-semibold mt-8 mb-4">7. Contact Us</h2>
            <p className="mb-4">
              For privacy-related questions or to exercise your rights, contact us at privacy@reminder.com.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}