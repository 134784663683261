import { useState, useEffect } from 'react';
import { supabase } from '../lib/supabase';
import type { SubscriptionStatus } from '../types/subscription';

export function useSubscription() {
  const [status, setStatus] = useState<SubscriptionStatus>({
    isSubscribed: false,
    expiresAt: null,
  });
  const [loading, setLoading] = useState(true);

  const checkSubscription = async () => {
    const { data: { session } } = await supabase.auth.getSession();
    if (!session) return;

    const { data, error } = await supabase
      .from('subscriptions')
      .select('*')
      .eq('user_id', session.user.id)
      .single();

    if (error) {
      console.error('Error checking subscription:', error);
      return;
    }

    if (data) {
      setStatus({
        isSubscribed: true,
        expiresAt: data.expires_at,
      });
    }

    setLoading(false);
  };

  useEffect(() => {
    checkSubscription();
  }, []);

  return { status, loading, checkSubscription };
}