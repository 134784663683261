import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../lib/supabase';
import { PhoneInput } from './PhoneInput';
import { validatePhone } from '../utils/validation';
import { NameInput } from './auth/NameInput';

interface AuthFormProps {
  type: 'login' | 'signup';
}

export function AuthForm({ type }: AuthFormProps) {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [phoneError, setPhoneError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setPhoneError(null);
    setLoading(true);

    try {
      if (type === 'signup') {
        const phoneValidationError = validatePhone(phone);
        if (phoneValidationError) {
          setPhoneError(phoneValidationError);
          setLoading(false);
          return;
        }

        const { error: signUpError } = await supabase.auth.signUp({
          email,
          password,
          options: {
            data: {
              full_name: name,
              phone_number: phone
            }
          }
        });
        
        if (signUpError) throw signUpError;
        
        // Redirect to Stripe payment page after successful signup
        window.location.href = 'https://buy.stripe.com/dR614rfRr7trdQA000';
      } else {
        const { error: signInError, data } = await supabase.auth.signInWithPassword({
          email,
          password,
        });
        
        if (signInError) throw signInError;

        // Check subscription status before redirecting to dashboard
        const { data: subscription } = await supabase
          .from('subscriptions')
          .select('status, expires_at')
          .eq('user_id', data.user.id)
          .single();

        if (!subscription || subscription.status !== 'active') {
          // Redirect to payment page if no active subscription
          window.location.href = 'https://buy.stripe.com/dR614rfRr7trdQA000';
          return;
        }
        
        navigate('/dashboard');
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4 max-w-sm mx-auto">
      {error && (
        <div className="bg-red-50 text-red-600 p-3 rounded-lg text-sm">
          {error}
        </div>
      )}
      
      {type === 'signup' && (
        <NameInput
          value={name}
          onChange={setName}
        />
      )}

      <div>
        <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
          Email
        </label>
        <input
          id="email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-indigo-500 focus:border-indigo-500"
          required
        />
      </div>

      <div>
        <label htmlFor="password" className="block text-sm font-medium text-gray-700 mb-1">
          Password
        </label>
        <input
          id="password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-indigo-500 focus:border-indigo-500"
          required
        />
      </div>

      {type === 'signup' && (
        <PhoneInput
          value={phone}
          onChange={setPhone}
          error={phoneError}
        />
      )}

      <button
        type="submit"
        disabled={loading}
        className="w-full bg-indigo-600 text-white py-2 px-4 rounded-lg hover:bg-indigo-700 transition-colors disabled:opacity-50"
      >
        {loading ? 'Loading...' : type === 'signup' ? 'Sign up' : 'Log in'}
      </button>
    </form>
  );
}