import React, { useState } from 'react';
import { Calendar, Trash2, Edit2, X, Check } from 'lucide-react';
import { formatDate } from '../../utils/date';
import { Reminder } from '../../types/reminder';
import { ReminderForm } from '../ReminderForm';

interface ReminderCardProps {
  reminder: Reminder;
  onDelete: (id: string) => void;
  onUpdate: (id: string, reminder: Partial<Omit<Reminder, 'id' | 'user_id' | 'created_at'>>) => void;
}

export function ReminderCard({ reminder, onDelete, onUpdate }: ReminderCardProps) {
  const [isEditing, setIsEditing] = useState(false);

  if (isEditing) {
    const datetime = new Date(reminder.datetime);
    const initialDate = datetime.toISOString().split('T')[0];
    const initialTime = datetime.toTimeString().slice(0, 5);

    return (
      <div className="bg-white p-4 rounded-lg shadow-sm border-2 border-indigo-100">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-semibold text-gray-900">Edit Reminder</h3>
          <button
            onClick={() => setIsEditing(false)}
            className="text-gray-400 hover:text-gray-600"
          >
            <X className="h-5 w-5" />
          </button>
        </div>
        <ReminderForm
          initialValues={{
            message: reminder.message,
            date: initialDate,
            time: initialTime,
            frequency: reminder.frequency,
          }}
          onSubmit={(updatedReminder) => {
            onUpdate(reminder.id, updatedReminder);
            setIsEditing(false);
          }}
          submitLabel="Save Changes"
        />
      </div>
    );
  }

  return (
    <div className="bg-white p-4 rounded-lg shadow-sm hover:shadow-md transition-shadow">
      <div className="flex items-center justify-between">
        <div className="flex-1 min-w-0">
          <p className="text-sm font-medium text-gray-900 truncate">
            {reminder.message}
          </p>
          <div className="mt-1 flex items-center text-sm text-gray-500">
            <Calendar className="h-4 w-4 mr-1" />
            <span>{formatDate(reminder.datetime)}</span>
            <span className="mx-2">•</span>
            <span className="capitalize">{reminder.frequency}</span>
          </div>
        </div>
        <div className="flex items-center space-x-2 ml-4">
          <button
            onClick={() => setIsEditing(true)}
            className="text-gray-400 hover:text-indigo-600 transition-colors"
            aria-label="Edit reminder"
          >
            <Edit2 className="h-5 w-5" />
          </button>
          <button
            onClick={() => onDelete(reminder.id)}
            className="text-gray-400 hover:text-red-600 transition-colors"
            aria-label="Delete reminder"
          >
            <Trash2 className="h-5 w-5" />
          </button>
        </div>
      </div>
    </div>
  );
}