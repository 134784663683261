import React from 'react';
import { Bell, Calendar, MessageSquare, Repeat, CheckCircle2 } from 'lucide-react';
import { Link } from 'react-router-dom';
import { Footer } from '../components/Footer';

export function LandingPage() {
  return (
    <div className="bg-gradient-to-b from-gray-50 to-white">
      {/* Hero Section */}
      <header className="container mx-auto px-4 py-16 text-center">
        <div className="flex justify-center mb-6">
          <Bell className="h-12 w-12 text-indigo-600" />
        </div>
        <h1 className="text-5xl font-bold text-gray-900 mb-6">
          Smart Text Reminders, <span className="text-indigo-600">Your Way</span>
        </h1>
        <p className="text-xl text-gray-600 mb-8 max-w-2xl mx-auto">
          Never miss important moments with customizable text reminders that work for you.
          Set once or recurring, it's your choice.
        </p>
        <Link 
          to="/signup"
          className="bg-indigo-600 text-white px-8 py-4 rounded-lg text-lg font-semibold hover:bg-indigo-700 transition-colors inline-block"
        >
          Sign Up Now
        </Link>
      </header>

      {/* Features Grid */}
      <section className="py-16 bg-white">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">Everything You Need for Perfect Timing</h2>
          <div className="grid md:grid-cols-3 gap-8">
            <div className="p-6 rounded-xl bg-gray-50">
              <Calendar className="h-8 w-8 text-indigo-600 mb-4" />
              <h3 className="text-xl font-semibold mb-2">Flexible Scheduling</h3>
              <p className="text-gray-600">Set one-time or recurring reminders with custom frequencies that match your needs.</p>
            </div>
            <div className="p-6 rounded-xl bg-gray-50">
              <MessageSquare className="h-8 w-8 text-indigo-600 mb-4" />
              <h3 className="text-xl font-semibold mb-2">Custom Messages</h3>
              <p className="text-gray-600">Personalize your reminder messages with custom text and dynamic variables.</p>
            </div>
            <div className="p-6 rounded-xl bg-gray-50">
              <Repeat className="h-8 w-8 text-indigo-600 mb-4" />
              <h3 className="text-xl font-semibold mb-2">Smart Recurrence</h3>
              <p className="text-gray-600">Daily, weekly, monthly, or custom intervals - you decide how often.</p>
            </div>
          </div>
        </div>
      </section>

      {/* Pricing */}
      <section className="py-16 bg-white">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">Simple, Transparent Pricing</h2>
          <div className="max-w-md mx-auto bg-white rounded-2xl shadow-lg overflow-hidden">
            <div className="bg-indigo-600 text-white p-6 text-center">
              <h3 className="text-2xl font-bold mb-2">Premium Plan</h3>
              <div className="text-4xl font-bold mb-2">$9.99<span className="text-lg">/month</span></div>
              <p className="opacity-90">Everything you need for reliable reminders</p>
            </div>
            <div className="p-6 space-y-4">
              <div className="flex items-center space-x-3">
                <CheckCircle2 className="h-5 w-5 text-green-500" />
                <span>Unlimited reminders</span>
              </div>
              <div className="flex items-center space-x-3">
                <CheckCircle2 className="h-5 w-5 text-green-500" />
                <span>Custom recurring schedules</span>
              </div>
              <div className="flex items-center space-x-3">
                <CheckCircle2 className="h-5 w-5 text-green-500" />
                <span>Personalized messages</span>
              </div>
              <div className="flex items-center space-x-3">
                <CheckCircle2 className="h-5 w-5 text-green-500" />
                <span>Priority support</span>
              </div>
              <Link
                to="/signup"
                className="block w-full bg-indigo-600 text-white text-center py-3 rounded-lg mt-6 hover:bg-indigo-700 transition-colors"
              >
                Sign Up Now
              </Link>
            </div>
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="py-16 bg-indigo-600">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-3xl font-bold text-white mb-6">Ready to Never Miss a Moment?</h2>
          <p className="text-xl text-indigo-100 mb-8 max-w-2xl mx-auto">
            Join thousands of users who trust our reminder service to stay on top of what matters.
          </p>
          <Link
            to="/signup"
            className="bg-white text-indigo-600 px-8 py-4 rounded-lg text-lg font-semibold hover:bg-gray-100 transition-colors inline-block"
          >
            Sign Up Now
          </Link>
        </div>
      </section>

      <Footer />
    </div>
  );
}