import React from 'react';
import { Link } from 'react-router-dom';

export function Footer() {
  return (
    <footer className="bg-gray-50 border-t border-gray-200">
      <div className="container mx-auto px-4 py-8">
        <div className="flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
          <div className="text-sm text-gray-600">
            © {new Date().getFullYear()} Reminder. All rights reserved.
          </div>
          <div className="flex items-center space-x-6">
            <Link to="/terms" className="text-sm text-gray-600 hover:text-gray-900">
              Terms & Conditions
            </Link>
            <Link to="/privacy" className="text-sm text-gray-600 hover:text-gray-900">
              Privacy Policy
            </Link>
            <a href="mailto:hello@immortaliscapital.com" className="text-sm text-gray-600 hover:text-gray-900">
              hello@immortaliscapital.com
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}